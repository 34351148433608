import { DateTime } from 'luxon';
import React, { useState } from 'react';

import IconQuestionCircle from '@cimpress-technology/react-streamline-icons/lib/IconQuestionCircle';
import { colors, NavTab, NavTabItem, TextField, Tooltip } from '@cimpress/react-components';

import evaluateTemplate from '../../../helpers/evaluatetemplate';

const inputTab = 'INPUT_TAB';
const previewTab = 'PREVIEW_TAB';

const evaluateMerchandizingText = (merchandizingText: string, locale?: string) => {
  // It doesn't really matter what these dates are, they are just for display.
  const earliestDate = DateTime.utc().toISO();
  const latestDate = DateTime.utc().plus({ days: 7 }).toISO();

  try {
    return evaluateTemplate({
      template: merchandizingText,
      locale,
      earliestDate,
      latestDate,
    });
  } catch {
    // If something goes wrong just display the template.
    return merchandizingText;
  }
};

const MerchandizingTextInput = ({
  locale,
  value,
  onChange,
}: {
  locale?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [selectedTab, setSelectedTab] = useState(inputTab);

  const input = (
    <TextField
      style={{ maxWidth: '595px', marginBottom: '0px' }}
      type="textarea"
      label="Merchandizing Text"
      value={value}
      onChange={onChange}
    />
  );

  const evaluatedMerchandizingText = evaluateMerchandizingText(value, locale);

  const preview = (
    <TextField
      style={{ maxWidth: '595px', marginBottom: '0px' }}
      type="textarea"
      label="Merchandizing Text Preview"
      value={evaluatedMerchandizingText}
      disabled
      onChange={() => undefined}
    />
  );

  return (
    <div>
      <NavTab style={{ marginBottom: '10px' }}>
        <NavTabItem active={selectedTab === inputTab}>
          <button onClick={() => setSelectedTab(inputTab)}>Input</button>
        </NavTabItem>
        <NavTabItem active={selectedTab === previewTab}>
          <button onClick={() => setSelectedTab(previewTab)}>Preview</button>
        </NavTabItem>
        <Tooltip contents="Click here for documentation">
          <a
            href="https://cimpress-support.atlassian.net/wiki/spaces/DEL/pages/10287513614/Using+Merchandizing+Text"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconQuestionCircle weight="fill" color={colors.info.base} style={{ marginTop: '9px' }} />
          </a>
        </Tooltip>
      </NavTab>
      {selectedTab === inputTab ? input : preview}
    </div>
  );
};

export default MerchandizingTextInput;
