import { v4 as uuid } from 'uuid';

export const earliest = 'earliest';
export const latest = 'latest';

const preselected = 'preselected';
const estimatedShipping = 'estimatedShipping';
const merchantCacheable = 'merchantCacheable';

export const availableTags = [
  { label: preselected, value: preselected },
  { label: estimatedShipping, value: estimatedShipping },
  { label: merchantCacheable, value: merchantCacheable },
];

export const cashOnDelivery = 'cashOnDelivery';
export const pickupPoints = 'pickupPoints';
export const moneyBackGuaranteedDelivery = 'moneyBackGuaranteedDelivery';

export const expectationLabels = {
  [cashOnDelivery]: 'Cash On Delivery',
  [pickupPoints]: 'Pickup Points',
  [moneyBackGuaranteedDelivery]: 'Money Back Guaranteed Delivery',
};

export const betweenDays = 'betweenDays';
export const betweenOffset = 'betweenOffset';

export const betweenSelection = { value: betweenDays, label: 'Between' };
export const betweenOffsetSelection = {
  value: betweenOffset,
  label: 'Fastest possible, offset between',
};

export type DeliveryOptionState = {
  id: string;
  name?: string;
  destinations: DestinationState[];
  bufferTime?: number | string;
  minDays?: number | string;
  maxDays?: number | string;
  minOffset?: number | string;
  maxOffset?: number | string;
  localCutoffTimeHour?: string;
  localCutoffTimeMinute?: string;
  dateSelection: string;
  minFallbackDays?: number | string;
  maxFallbackDays?: number | string;
  displayNames: DisplayNameState[];
  dispatchCountry?: string;
  carrierServices: string[];
  requireCarriers: boolean;
  carrierServiceCapabilities: string[];
  fulfillmentCapabilities: string[];
  ignoreInTransitInventory: boolean;
  tags: string[];
  deliveryTierId?: string;
  hasUnsavedWork: boolean;
  isNewOption: boolean;
  isDeleted: boolean;
  isValid: boolean;
};

export type DisplayNameState = {
  id: string;
  locale?: string;
  name?: string;
  description?: string;
  merchandizingText?: {
    template: string;
  }[];
};

export type DestinationState = {
  id: string;
  country?: string;
  postalCodeRanges: PostalCodeRange[];
};

export type PostalCodeRange = {
  id: string;
  start?: string;
  end?: string;
};

export const initialDeliveryOption = () => {
  return {
    id: uuid(),
    name: undefined,
    destinations: [],
    bufferTime: 0,
    minDays: undefined,
    maxDays: undefined,
    minOffset: undefined,
    maxOffset: undefined,
    localCutoffTimeHour: undefined,
    localCutoffTimeMinute: undefined,
    dateSelection: earliest,
    minFallbackDays: undefined,
    maxFallbackDays: undefined,
    displayNames: [],
    dispatchCountry: undefined,
    carrierServices: [],
    requireCarriers: false,
    carrierServiceCapabilities: [],
    fulfillmentCapabilities: [],
    ignoreInTransitInventory: false,
    tags: [],
    deliveryTierId: undefined,
    hasUnsavedWork: true,
    isNewOption: true,
    isDeleted: false,
    isValid: false,
  };
};

export const initialDestination = () => {
  return {
    id: uuid(),
    country: undefined,
    postalCodeRanges: [],
  };
};

export const initialPostalCodeRange = () => {
  return {
    id: uuid(),
    start: undefined,
    end: undefined,
  };
};

export const initialDisplayName = () => {
  return {
    id: uuid(),
    locale: undefined,
    name: undefined,
    description: undefined,
    merchandizingText: [],
  };
};
