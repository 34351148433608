import { css } from '@emotion/css';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import {
  Accordion,
  Button,
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
  Select,
  InlineEdit,
  colors,
  CopyText,
  Modal,
} from '@cimpress/react-components';

import { isDeliveryOptionStateValid } from '../../../helpers/validateStates';
import {
  setField,
  addDestination,
  removeDestination,
  addDisplayName,
  removeDeliveryOption,
  validateDeliveryOption,
} from '../../../reducers/deliveryoption/deliveryOptionActions';
import {
  earliest,
  latest,
  availableTags,
  DeliveryOptionState,
} from '../../../reducers/deliveryoption/deliveryOptionConstants';
import { SelectField } from '../../../types/shared';
import DisplayNameEditor from '../../shared/DisplayNameEditor';
import InformationHeader from '../../shared/InformationHeader';
import { CarrierServiceCapabilitiesSelect } from './CarrierServiceCapabilitiesSelect';
import { CarrierServicesSelect } from './CarrierServicesSelect';
import CountrySelect from './CountrySelect';
import DeliveryDays from './DeliveryDays';
import DestinationEditor from './DestinationEditor';
import FallbackDays from './FallbackDays';
import FulfillmentCapabilitiesSelect from './FulfillmentCapabilitiesSelect';

const hours = Array.from({ length: 24 }, (_, i) => `${i < 10 ? '0' : ''}${i}`);
const minutes = Array.from({ length: 12 }, (_, i) => `${i < 2 ? '0' : ''}${i * 5}`);

export const fieldCss = css`
  width: 60%;
`;

const displayCss = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 300px;
`;

const cutoffTimeCss = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const DeliveryOptionEditor = ({
  deliveryOption,
  dispatch,
  edoId,
}: {
  deliveryOption: DeliveryOptionState;
  dispatch: React.Dispatch<React.SetStateAction<unknown>>;
  edoId: string;
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const isValidState = isDeliveryOptionStateValid(deliveryOption);
    dispatch(validateDeliveryOption({ edoId, isValid: isValidState }));
  }, [deliveryOption, dispatch, edoId]);

  const updateTagSelection = (tagSelections: SelectField[]) => {
    const value = tagSelections?.map(selection => selection.value);
    dispatch(setField({ edoId, field: 'tags', value }));
  };

  const updateDispatchCountry = (dispatchCountry?: string) => {
    dispatch(setField({ edoId, field: 'dispatchCountry', value: dispatchCountry }));
  };

  const updateCarrierServices = (carrierServices: string[]) => {
    dispatch(setField({ edoId, field: 'carrierServices', value: carrierServices }));
  };

  const updateCarrierServiceCapabilities = (carrierServiceCapabilities: string[]) => {
    dispatch(setField({ edoId, field: 'carrierServiceCapabilities', value: carrierServiceCapabilities }));
  };

  const updateFulfillmentCapabilities = (fulfillmentCapabilities: string[]) => {
    dispatch(setField({ edoId, field: 'fulfillmentCapabilities', value: fulfillmentCapabilities }));
  };

  const onChangeDispatchCountry = (selectedDispatchCountry?: SelectField | null) => {
    updateDispatchCountry(selectedDispatchCountry?.value);
  };

  const onChangeCarrierServices = (selectedCarriers: SelectField[]) => {
    const carrierServices = selectedCarriers?.map(carrier => carrier.value) ?? [];
    updateCarrierServices(carrierServices);
  };

  const updateRequireCarriers = (requireCarriers: boolean) => {
    dispatch(setField({ edoId, field: 'requireCarriers', value: requireCarriers }));
  };

  const onChangeRequireCarriers = (e: { target: { value: any } }) => {
    const requireCarriers = Boolean(e.target.value);
    updateRequireCarriers(requireCarriers);
  };

  const onChangeCarrierServiceCapabilities = (selectedCarrierCaps: SelectField[]) => {
    const carrierCaps = selectedCarrierCaps?.map(carrierCap => carrierCap.value) ?? [];
    updateCarrierServiceCapabilities(carrierCaps);
  };

  const onChangeFulfillmentCapabilities = (selectedFcaps: SelectField[]) => {
    const fcaps = selectedFcaps?.map(fcap => fcap.value) ?? [];
    updateFulfillmentCapabilities(fcaps);
  };

  const accordionHeader = (
    <InlineEdit
      size="h3"
      placeholder={'Delivery Option Name'}
      value={deliveryOption.name}
      onKeyDownCapture={e => e.key === ' ' && e.stopPropagation()}
      onClickCapture={e => e.stopPropagation()}
      onChange={e => dispatch(setField({ edoId, field: 'name', value: e.target.value }))}
    />
  );

  const deleteModal = (
    <Modal
      show={showModal}
      status="danger"
      onRequestHide={() => setShowModal(false)}
      title={`Delete '${deliveryOption.name}' Delivery Option?`}
      closeButton={true}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button size="lg" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            size="lg"
            variant="primary"
            style={{ background: colors.danger.base, border: colors.danger.base }}
            onClick={() => dispatch(removeDeliveryOption({ edoId }))}
          >
            Delete
          </Button>
        </div>
      }
    >
      Are you sure you want to remove this delivery option?<br></br>
      <b>Doing so will delete this resource entirely.</b> <br></br>
      Alternatively, you can remove this as an available delivery option on the Product Rules tab.
    </Modal>
  );

  const deleteButton = (
    <Button
      variant="link"
      aria-label="Delete"
      onClick={() => (deliveryOption.isNewOption ? dispatch(removeDeliveryOption({ edoId })) : setShowModal(true))}
      icon={<IconBin size="lg" />}
    />
  );

  const areAdvancedSettingsPopulated =
    Boolean(deliveryOption.ignoreInTransitInventory) ||
    Boolean(deliveryOption.bufferTime) ||
    deliveryOption.dateSelection === latest ||
    Boolean(deliveryOption.dispatchCountry) ||
    !isEmpty(deliveryOption.carrierServiceCapabilities) ||
    !isEmpty(deliveryOption.carrierServices) ||
    !isEmpty(deliveryOption.fulfillmentCapabilities) ||
    !isEmpty(deliveryOption.tags);

  return (
    <div>
      {deleteModal}
      <Accordion
        title={accordionHeader}
        actions={
          <div style={{ display: 'flex' }}>
            {!deliveryOption.isValid && (
              <IconAlertTriangle size="lg" weight="fill" color={colors.danger.base} style={{ marginTop: 12 }} />
            )}
            {deleteButton}
          </div>
        }
      >
        <div className={displayCss}>
          <div>
            {!deliveryOption.isNewOption && (
              <div style={{ marginBottom: 10 }}>
                <InformationHeader
                  header="Delivery Option Identifier"
                  contents="Unique ID associated with this delivery option"
                />
                <CopyText>{deliveryOption.id}</CopyText>
              </div>
            )}
            <InformationHeader
              header="Display Names"
              contents="Configure delivery option names and descriptions to display on website by locale"
            />
            {deliveryOption.displayNames.map(displayName => (
              <DisplayNameEditor
                displayName={displayName}
                dispatch={dispatch}
                key={displayName.id}
                resourceId={edoId}
              />
            ))}
            <Button
              style={{ marginTop: '10px', marginBottom: '10px' }}
              onClick={() => dispatch(addDisplayName({ resourceId: edoId }))}
            >
              Add Display Name
            </Button>
            <InformationHeader
              header="Delivered"
              contents="Choose a delivery time based on business days or an offset from the fastest possible delivery date. Fallbacks can't use offsets."
            />
            <DeliveryDays deliveryOption={deliveryOption} dispatch={dispatch} edoId={edoId} />
            <InformationHeader
              header="Fallback Days"
              contents="Choose a day range to fallback to in the case of an error retrieving dates for this delivery option. If using fallback days, must set both min and max."
            />
            <FallbackDays deliveryOption={deliveryOption} dispatch={dispatch} edoId={edoId} />
            <InformationHeader header="End of Business Day" contents="Format: HH:mm (e.g. 10:00)" />
            <div className={cutoffTimeCss}>
              <Select
                label="HH"
                options={hours.map(hour => ({ label: hour, value: hour }))}
                value={
                  deliveryOption.localCutoffTimeHour
                    ? {
                        label: deliveryOption.localCutoffTimeHour,
                        value: deliveryOption.localCutoffTimeHour,
                      }
                    : undefined
                }
                onChange={selection =>
                  selection && dispatch(setField({ edoId, field: 'localCutoffTimeHour', value: selection.value }))
                }
                status={deliveryOption.localCutoffTimeHour ? undefined : 'error'}
              />
              <Select
                label="MM"
                options={minutes.map(minute => ({ label: minute, value: minute }))}
                value={
                  deliveryOption.localCutoffTimeMinute
                    ? {
                        label: deliveryOption.localCutoffTimeMinute,
                        value: deliveryOption.localCutoffTimeMinute,
                      }
                    : undefined
                }
                onChange={selection =>
                  selection && dispatch(setField({ edoId, field: 'localCutoffTimeMinute', value: selection.value }))
                }
                status={deliveryOption.localCutoffTimeMinute ? undefined : 'error'}
              />
            </div>
          </div>
          <div style={{ maxWidth: 500 }}>
            <InformationHeader
              header="Destinations"
              contents="If no destinations are selected, this delivery option will apply to all destinations."
            />
            {deliveryOption.destinations.map(destination => (
              <div key={destination.id} style={{ display: 'flex', marginBottom: 10 }}>
                <DestinationEditor
                  style={{ width: '100%' }}
                  destination={destination}
                  dispatch={dispatch}
                  edoId={edoId}
                />
                <Button
                  style={{ height: '50px', marginLeft: 10 }}
                  variant="link"
                  onClick={() => dispatch(removeDestination({ edoId, destinationId: destination.id }))}
                >
                  <IconBin className="text-info" />
                </Button>
              </div>
            ))}
            <Button style={{ marginTop: 10, marginBottom: 10 }} onClick={() => dispatch(addDestination({ edoId }))}>
              Add Destination
            </Button>
          </div>
        </div>
        <Accordion
          title={'Advanced Settings'}
          variant={'minimal'}
          headerStyle={{ paddingLeft: 0 }}
          defaultOpen={areAdvancedSettingsPopulated}
        >
          <InformationHeader
            header="In-Transit Inventory Consideration"
            contents="Indicate if you would like inventory in transit to be considered when calculating delivery dates. In-transit inventory will automatically be taken into consideration unless specified otherwise below."
          />

          <Checkbox
            label="Exclude in-transit inventory availability when calculating delivery dates"
            checked={deliveryOption.ignoreInTransitInventory}
            onChange={() =>
              dispatch(
                setField({ edoId, field: 'ignoreInTransitInventory', value: !deliveryOption.ignoreInTransitInventory }),
              )
            }
          />
          <InformationHeader
            header="MCP Buffer Time"
            contents="Amount of time in minutes the order is held until being sent to MCP"
          />
          <TextField
            className={fieldCss}
            label="MCP buffer time"
            type="number"
            min="0"
            value={deliveryOption.bufferTime}
            onChange={e => dispatch(setField({ edoId, field: 'bufferTime', value: e.target.value }))}
          />
          <InformationHeader
            header="Date Selection"
            contents="Used to determine a date to show to a customer on a checkout page"
          />
          <RadioGroup
            name="dateSelectionRadio"
            onChange={e => dispatch(setField({ edoId, field: 'dateSelection', value: e.target.value }))}
            defaultSelected={deliveryOption.dateSelection ?? earliest}
          >
            <Radio label="Display the earliest possible date across all items in a cart" value={earliest} />
            <Radio label="Display the latest date across all items in a cart" value={latest} />
          </RadioGroup>
          <InformationHeader header="Delivery Constraints" />
          <CountrySelect
            className={fieldCss}
            value={deliveryOption.dispatchCountry ?? ''}
            onChange={onChangeDispatchCountry}
          />
          <CarrierServiceCapabilitiesSelect
            className={fieldCss}
            values={deliveryOption.carrierServiceCapabilities}
            onChange={onChangeCarrierServiceCapabilities}
          />
          <CarrierServicesSelect
            className={fieldCss}
            value={deliveryOption.carrierServices ?? []}
            required={deliveryOption.requireCarriers}
            onChange={onChangeCarrierServices}
            onChangeRequirement={onChangeRequireCarriers}
          />
          <InformationHeader header="Fulfillment Capabilities" />
          <FulfillmentCapabilitiesSelect
            className={fieldCss}
            value={deliveryOption.fulfillmentCapabilities}
            onChange={onChangeFulfillmentCapabilities}
          />
          <InformationHeader header="Tags" />
          <Select
            className={fieldCss}
            isClearable
            label="Available Tags"
            // @ts-ignore
            value={deliveryOption.tags?.map((tag: string) => ({ label: tag, value: tag }))}
            options={availableTags}
            menuPortalTarget={document.body}
            // @ts-ignore
            onChange={updateTagSelection}
            // @ts-ignore
            isMulti
          />
        </Accordion>
      </Accordion>
    </div>
  );
};

export default DeliveryOptionEditor;
