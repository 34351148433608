import { css } from '@emotion/css';
import React, { useEffect } from 'react';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { Accordion, Button, RadioGroup, Radio, InlineEdit, colors } from '@cimpress/react-components';

import { isDeliveryTierStateValid } from '../../../helpers/validateStates';
import { addDisplayName } from '../../../reducers/deliveryoption/deliveryOptionActions';
import { earliest, latest } from '../../../reducers/deliveryoption/deliveryOptionConstants';
import { removeDeliveryTier, setField, validateDeliveryTier } from '../../../reducers/deliverytier/deliveryTierActions';
import { DeliveryTierState } from '../../../reducers/deliverytier/deliveryTierConstants';
import { SelectField } from '../../../types/shared';
import DisplayNameEditor from '../../shared/DisplayNameEditor';
import InformationHeader from '../../shared/InformationHeader';
import TagSelect from '../../shared/TagSelect';

export const fieldCss = css`
  width: 60%;
`;

const displayCss = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 300px;
`;

export const DeliveryTierEditor = ({
  deliveryTier,
  dispatch,
  tierId,
}: {
  deliveryTier: DeliveryTierState;
  dispatch: React.Dispatch<React.SetStateAction<unknown>>;
  tierId: string;
}) => {
  useEffect(() => {
    const isValidState = isDeliveryTierStateValid(deliveryTier);
    dispatch(validateDeliveryTier({ tierId, isValid: isValidState }));
  }, [deliveryTier, dispatch, tierId]);

  const updateTagSelection = (tagSelections: SelectField[]) => {
    const value = tagSelections?.map(selection => selection.value);
    dispatch(setField({ tierId, field: 'tags', value }));
  };

  const accordionHeader = (
    <InlineEdit
      size="h3"
      placeholder={'Delivery Tier Name'}
      value={deliveryTier.name}
      onKeyDownCapture={e => e.key === ' ' && e.stopPropagation()}
      onClickCapture={e => e.stopPropagation()}
      onChange={e => dispatch(setField({ tierId, field: 'name', value: e.target.value }))}
    />
  );

  const deleteButton = (
    <Button
      variant="link"
      aria-label="Delete"
      onClick={() => dispatch(removeDeliveryTier({ tierId }))}
      icon={<IconBin size="lg" />}
    />
  );

  return (
    <Accordion
      title={accordionHeader}
      actions={
        <div style={{ display: 'flex' }}>
          {!deliveryTier.isValid && (
            <IconAlertTriangle size="lg" weight="fill" color={colors.danger.base} style={{ marginTop: 12 }} />
          )}
          {deleteButton}
        </div>
      }
    >
      <div className={displayCss}>
        <div>
          <InformationHeader
            header="Display Names"
            contents="Configure delivery tier names and descriptions to display on website by locale"
          />
          {deliveryTier.displayNames.map(displayName => (
            <DisplayNameEditor displayName={displayName} dispatch={dispatch} key={displayName.id} resourceId={tierId} />
          ))}
          <Button
            style={{ marginTop: '10px', marginBottom: '10px' }}
            onClick={() => dispatch(addDisplayName({ resourceId: tierId }))}
          >
            Add Display Name
          </Button>
          <InformationHeader
            header="Date Selection"
            contents="Used to determine a date to show to a customer on a checkout page"
          />
          <RadioGroup
            name="dateSelectionRadio"
            onChange={e => dispatch(setField({ tierId, field: 'dateSelection', value: e.target.value }))}
            defaultSelected={deliveryTier.dateSelection ?? earliest}
          >
            <Radio label="Display the earliest possible date across all items in a cart" value={earliest} />
            <Radio label="Display the latest date across all items in a cart" value={latest} />
          </RadioGroup>
          <InformationHeader header="Tags" />
          <TagSelect
            tags={deliveryTier.tags?.map((tag: string) => ({ label: tag, value: tag })) ?? []}
            updateTagSelection={updateTagSelection}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default DeliveryTierEditor;
