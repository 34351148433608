import { css } from '@emotion/css';
import find from 'lodash/find';
import React, { useState } from 'react';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconArrowThickRight from '@cimpress-technology/react-streamline-icons/lib/IconArrowThickRight';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import SkuAttributeModelBuilder from '@cimpress-technology/sku-attribute-model-builder';
import { Button, Modal, Select, TextField, colors, Accordion, InlineEdit } from '@cimpress/react-components';

import auth from '../../../auth';
import { isMappingStateValid } from '../../../helpers/validateStates';
import { removeMapping, setMappingField } from '../../../reducers/deliverygroup/deliveryGroupActions';
import { MappingState } from '../../../reducers/deliverygroup/deliveryGroupConstants';
import { DeliveryOptionState } from '../../../reducers/deliveryoption/deliveryOptionConstants';
import { SelectField } from '../../../types/shared';

const accordionBody = css`
  &.accordion .accordion-body {
    padding: 0 25px 0 45px;
    width: 100%;
  }
`;

const BuilderDisplay = css`
  margin-bottom: 100px;
`;

const MappingDisplay = css`
  display: grid;
  grid-template-columns: 40% 20% 40%;
  align-items: center;
  .sku-preview {
    display: none;
  }
`;

const accessToken = auth.getAccessToken();
const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

const MappingEditor = ({
  mapping,
  deliveryOptions,
  dispatch,
}: {
  mapping: MappingState;
  deliveryOptions: DeliveryOptionState[];
  dispatch: React.Dispatch<unknown>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const mappingId = mapping.mappingId;

  const updateAttributeModelUrl = (attributeModelUrl: string) => {
    dispatch(setMappingField({ mappingId, field: 'attributeModelUrl', value: attributeModelUrl }));
  };

  const updateSkus = (skus: string[]) => {
    dispatch(setMappingField({ mappingId, field: 'skus', value: skus }));
  };

  const setSkus = (commaSeparatedSkus: string) => {
    const skus = commaSeparatedSkus.split(',').map(sku => sku.trim());
    updateSkus(skus);
  };

  const selectEcommerceDeliveryOptions = (selectedEdos: SelectField[]) =>
    dispatch(
      setMappingField({
        mappingId,
        field: 'edoIds',
        value: selectedEdos?.map(selectedEdo => selectedEdo.value) ?? [],
      }),
    );

  const onPublish = (_: any, { resourceUrl, mcpSkus }: { resourceUrl: string; mcpSkus: string[] }) => {
    updateAttributeModelUrl(resourceUrl);
    updateSkus(mcpSkus);
    setShowModal(false);
  };

  const deliveryOptionChoices: SelectField[] = deliveryOptions.map(option => ({
    label: option?.name ?? `N/A`,
    value: option.id,
  }));

  const selectedEcommerceDeliveryOptions: SelectField[] = mapping.edoIds.map(edoId => {
    const mappingEdo = find(deliveryOptions, edo => edo.id === edoId);
    return { label: mappingEdo?.name ?? 'N/A', value: edoId };
  });

  const accordionHeader = (
    <InlineEdit
      placeholder={'Override Name'}
      value={mapping.name}
      onKeyDownCapture={e => e.key === ' ' && e.stopPropagation()}
      onClickCapture={e => e.stopPropagation()}
      onChange={e =>
        dispatch(
          setMappingField({
            mappingId: mapping.mappingId,
            field: 'name',
            value: e.target.value,
          }),
        )
      }
    />
  );

  const deleteButton = (
    <Button
      variant="link"
      aria-label="Delete"
      onClick={() => dispatch(removeMapping({ mappingId }))}
      icon={<IconBin size="lg" />}
    />
  );

  return (
    <Accordion
      title={accordionHeader}
      className={accordionBody}
      actions={
        <div style={{ display: 'flex' }}>
          {!isMappingStateValid(mapping) && (
            <IconAlertTriangle size="lg" weight="fill" color={colors.danger.base} style={{ marginTop: 12 }} />
          )}
          {deleteButton}
        </div>
      }
    >
      {/*TODO: DIS-2959 - remove the modal and display attribute model builder within accordion*/}
      <Modal show={showModal} onRequestHide={() => setShowModal(false)} title="Create an Attribute Model" closeButton>
        <div className={BuilderDisplay}>
          <SkuAttributeModelBuilder
            token={accessToken}
            resourceUrl={mapping.attributeModelUrl}
            mcpSkus={mapping.skus}
            isProduction={environment === 'Production'}
            onPublish={onPublish}
          />
        </div>
      </Modal>
      <div className={MappingDisplay}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {mapping.attributeModelUrl ? (
            <SkuAttributeModelBuilder
              token={accessToken}
              resourceUrl={mapping.attributeModelUrl}
              isReadOnly
              mcpSkus={mapping.skus}
              isProduction={environment === 'Production'}
              onPublish={onPublish}
            />
          ) : (
            <Button style={{ marginBottom: '10px' }} onClick={() => setShowModal(true)}>
              Create Attribute Model
            </Button>
          )}
          <TextField
            label="Comma Separated Skus"
            value={mapping.skus?.join(',') ?? ''}
            onChange={e => setSkus(e.target.value)}
          />
        </div>
        <IconArrowThickRight style={{ marginLeft: 50 }} size="5x" weight="light" color={colors.teal.base} />
        <Select
          label="Delivery Options"
          // @ts-ignore
          value={selectedEcommerceDeliveryOptions}
          options={deliveryOptionChoices}
          // @ts-ignore
          onChange={selectEcommerceDeliveryOptions}
          menuPortalTarget={document.body}
          required
          // @ts-ignore
          isMulti
        />
      </div>
    </Accordion>
  );
};

export default MappingEditor;
